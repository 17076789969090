<template>
    <div id="page_style" class="rows site-content page__style page__description" autocomplete="off">
         
        <div class="container-fluid banner mz-5">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
        </div>

        <!-- <div class="column is-half is-offset-one-quarter" style="padding-top: 0; margin-top: 0">
            <div v-if="results.length > 0" class="result-list-info">
                <div v-if="hasSearchTerm()" class="resultheader">
                    Your search term {{ "'" + stringSearchTerm + "'" }} yielded <strong>{{ numFound }}</strong> results:
                </div>
            </div>
            <div v-else-if="results.length == 0">
                <div class="resultheader">
                    Your search yielded
                    <strong> 0</strong> results:
                </div>
            </div>
        </div> -->

        <!-- ASK ELNAZ! ------------------------------------------------------------------------------------------------- -->
        <div class="columns">
            <div id="id-side-bar" class="column is-4 sidebar_column" style="padding-top: 0rem; padding-right: 1.5rem; padding-left: 1.5rem">

            </div>

            <div class="col col-8 column is-8 results_column" style="padding-top: 0.5rem; padding-right: 1rem; padding-left: 1rem; padding-bottom: 0rem;">

                <!-- <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="resultheader">
                        Your search term {{ "'" + stringSearchTerm + "'" }} yielded <strong>{{ numFound }}</strong> results:
                    </div>
                </div> -->
                
                <!-- Option 1: Enclose in a Colored Box -->
                <!-- <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="bg-blue-50 border-l-4 border-blue-400 p-4">
                        <p class="text-sm text-blue-700">
                            Your search term <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>{{ numFound }}</strong> results:
                        </p>
                    </div>
                </div> -->
                
                <!-- Option 2: Use a Notice/Alert Style -->
                <!--
                <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="bg-yellow-100 border border-yellow-300 text-yellow-800 px-4 py-3 rounded-md" role="alert">
                        <span class="block sm:inline">Your search term <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>{{ numFound }}</strong> results:</span>
                    </div>
                </div>
                -->

                <!-- Option 3: Highlight with a Callout -->
                
                <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <span class="font-medium pl-5">Your search term</span> <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>{{ numFound }}</strong> results:
                    </div>
                </div>
               
                <!-- Option 4: Bold with Underline -->
                <!--
                <div v-if="results.length > 0" class="result-list-info">
                    <div v-if="hasSearchTerm()" class="text-lg font-bold underline text-gray-800">
                        Your search term <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> yielded <strong>{{ numFound }}</strong> results:
                    </div>
                </div>
                -->

                <div v-else-if="results.length == 0">
                    <div class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <span class="font-medium pl-5">Your search yielded <strong> 0</strong> results:</span>
                        <!-- Your search yielded
                        <strong> 0</strong> results: -->
                    </div>
                </div>

                <!-- <div v-else-if="results.length == 0">
                    <div class="resultheader">
                        Your search yielded
                        <strong> 0</strong> results:
                    </div>
                </div> -->

            </div>

        </div>



        <div class="columns">
            <div id="id-side-bar" class="column is-4 sidebar_column" style="padding-top: 0.5rem; padding-right: 1.5rem; padding-left: 1.5rem">
                <div id="externals" class="">
                    <div v-for="(facetItems, key, index) in facets" v-bind:key="index" name="external_card" style="margin-bottom: 0px">
                        <facet-category v-bind:facetItems="facetItems" v-bind:filterName="key" @filter="onFilter"></facet-category>
                    </div>
                </div>
            </div>

            <div class="col col-8 column is-8 results_column" style="padding-top: 0.5rem; padding-right: 1rem; padding-left: 1rem">
                <div v-if="activeFilterCategories && Object.keys(activeFilterCategories).length > 0" class="column">
                    <span v-for="(values, key, index) in activeFilterCategories" v-bind:key="index" class="active-filter-items">
                        <!-- {{ values }} {{key}} {{index }} -->
                        <active-facet-category
                            v-bind:filterItems="values"
                            v-bind:categoryName="key"
                            @clear-facet-category="onClearFacetCategoryOPEN"
                        ></active-facet-category>
                    </span>
                </div>
                <div class="results">
                    <!-- pagination before search results -->
                    <PaginationComponent class="mb-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                    <!-- Results section -->
                    <vs-result v-bind:datasets="results"></vs-result>
                    <!-- pagination after serach results -->
                    <PaginationComponent class="mt-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                </div>
            </div>

            <!-- <PaginationComponent v-bind:data="pagination"></PaginationComponent> -->
        </div>

        <div class="container-fluid">
            <!-- <div class="columns is-mobile partner-logos"> -->
            <div class="columns">
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <!-- <h5 class="card-title">About TETHYS</h5> -->
                            <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                                <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 28rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="http://www.geosphere.at/">
                                <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                                <img src="@/assets/site/img/base_logo.png" alt="logo base" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import SearchViewComponent from "./search-view-component";
export default SearchViewComponent;
// import tailwind ss
import "@/index.css";
</script>

<style scoped></style>
