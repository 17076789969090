import { FacetItem } from "@/models/headers";
// import { FilterItem } from "@/models/solr";
import { Component, Vue, Prop, Emit } from "vue-facing-decorator";
// import { Prop, Emit } from "vue-property-decorator";

@Component({
    name: "FacetCategory",
})
export default class FacetCategory extends Vue {
    ITEMS_PER_FILTER = 2;
    bar = "";
    collapsed = true;

    @Prop()
    facetItems!: Array<FacetItem>;

    @Prop({
        type: String,
    })
    filterName!: string;

    replacements = new Map<string, string>([
        ["gis", "GIS"],
        ["analysisdata", "Analysis Data"],
        ["models", "Models"],
        ["monitoring", "Monitoring"],
        ["measurementdata", "Measurement Data"],
        ["mixedtype", "Mixed Type"],
        ["de", "Deutsch"],
        ["en", "English"]
    ]);

    /**
     * The alias for the Active facet box will be set depending on the name of the category. 
     * This will allow to display the customised terms "creator" and "keyword" instead of the values currently used in the OpenSearch index: "author" and "subjects"
     * NOTE: This should be corrected directly in the index
     */
    get categoryAlias(): string {
        // console.log("filterName:", this.filterName);
        // return this.filterName == "datatype" ? "doctype" : this.filterName;

        switch (this.filterName) {
            case "author":
                return "creator";
            case "subjects":
                return "keyword";
            case "doctype":
                    return "Data Type";
            default:
                return this.filterName;
        }
    }

    /**
     * The alias for the items inside the "doctype / Datatype" category will be set manually in order to show user-friendly terms instead of the predefined doctypes in the DB
     * NOTE: This could be corrected directly in the index
     */
    itemAlias(val: string): string {
        return this.replacements.get(val) || val;
    }

    // get filterItems(): Array<FilterItem> {
    //     return this.data;
    // }

    get overflowing(): boolean {
        //ko.observable(self.filterItems().length - self.activeFilterItems().length > ITEMS_PER_FILTER);
        return this.facetItems.length > this.ITEMS_PER_FILTER;
    }

    get uncollapseLabelText(): string {
        if (this.collapsed == true) {
            // return myLabels.viewer.sidePanel.more; //"More results";
            return "more";
        } else {
            // return myLabels.viewer.sidePanel.collapse; //"Collapse";
            return "collapse";
        }
    }

    toggle(): void {
        if (this.collapsed == true) {
            this.collapsed = false;
        } else if (this.collapsed == false) {
            this.collapsed = true;
            //list.children("li:gt(4)").hide();
        }
    }

    @Emit("filter")
    activateItem(filterItem: FacetItem): FacetItem {
        console.log("Emit: ActivateItem");
        // filterItem.category = this.alias;
        filterItem.category = this.filterName;
        filterItem.active = true;
        // this.$emit("filter", filterItem);
        return filterItem;
    }
}
